/* eslint-disable @next/next/no-img-element */
'use client';
import clsx from 'clsx';
import { useClientTranslation } from '@/hook/translate';
import { ListenBriefImg } from '@/config/img/listenbiref';
import { Image } from '@nextui-org/react';

export default function Footer(props: {
  className?: string;
}) {
  const { t } = useClientTranslation();
  return (
    <div className={
      clsx(' pb-16 flex flex-col items-center bg-black',
        'pb-10',
        props.className,
      )
    }>
      <Image 
        src={ ListenBriefImg.ListenbriefLogo }
        alt='logo'
        className={
          clsx('w-40 h-8',
            'max-md:w-[100px] max-md:h-5',
          )
        }
      />
      <p className={
        clsx('text-white', 'pt-2', 'text-2xl',
          'max-md:text-sm',
        )
      }>{ t('title', 'listenBriefs') }</p>
      <p className='text-[rgba(255,255,255,0.65)] text-12-17'>{ t('contact') }: buildlearn.bk@gmail.com</p>
    </div>
  );
}

import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/jenkins_home/workspace/outstation.web_main/app/listenbrief.com/[lng]/CusFooter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/jenkins_home/workspace/outstation.web_main/app/listenbrief.com/[lng]/home/[...category]/sub/FooterPagination.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/jenkins_home/workspace/outstation.web_main/app/listenbrief.com/[lng]/home/[...category]/sub/Header.tsx");
;
import(/* webpackMode: "eager" */ "/var/jenkins_home/workspace/outstation.web_main/components/BigTabItem/bigTabItem.module.scss");
;
import(/* webpackMode: "eager" */ "/var/jenkins_home/workspace/outstation.web_main/app/listenbrief.com/css/module/home.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/jenkins_home/workspace/outstation.web_main/components/ListenCard/HorizontalCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/jenkins_home/workspace/outstation.web_main/components/ListenCard/VerticalCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/jenkins_home/workspace/outstation.web_main/node_modules/next/dist/client/link.js");

import clsx from 'clsx';
import styles from './pagination.module.scss';
import { IPaginationProps } from '@/types/pagination';
import GotoText from './sub/GotoText';
import usePagination from './usePagination';
import { ListenBriefImg } from '@/config/img/listenbiref';
import { Image } from '@nextui-org/react';

interface IProps extends IPaginationProps {
  prevImgURL?: string;
  nextImgUrl?: string;
  textStyle?: string;
  btnStyle?: string;
  inputStyle?: string;
  activeTextStyle?: string;
}


export default function SmPagination(props: IProps) {
  const {
    // range,
    setPage,
    activePage,
  } = usePagination(props);
  const { total } = props;
  return (
    <div className={
      clsx('pagination-custom flex justify-center items-center', props.className)
    }>
      <div
        className={
          clsx('left-arrow w-[60px] h-[45px] flex items-center justify-center mr-5 cursor-pointer', styles.borderColor)
        }
        onClick={() => {
          if (activePage <= 1) {
            return ;
          }
          setPage(activePage - 1);
        }}
      >
        <Image src={ props.prevImgURL ?? ListenBriefImg.LeftArrow } alt='go prev page' className='w-4 h-4' />
      </div>
      <div className='page-content flex flex-1 items-center justify-center'>
          <div className={ props.textStyle ?? 'font-medium text-sm leading-[19px] text-white' }>{ props.curPage }/{ props.total }</div>
          <div className={ clsx('pl-[15px]',  props.textStyle ?? ' font-medium text-xs leading-[17px] text-white')}><GotoText /></div>
          <input
            type='number'
            max={ props.total }
            min={ 1 }
            onBlur={(e) => setPage(Number(e.target.value))}
            className={ clsx('w-[45px] h-[35px] ml-1', props.inputStyle ?? 'rounded-[4px] bg-[rgba(255,255,255,0.2)] font-medium text-base text-center') }
          />
      </div>
      <div 
        className={
          clsx('left-arrow w-[60px] h-[45px] flex items-center justify-center ml-5 cursor-pointer', styles.borderColor)
        }
        onClick={() => {
          if (activePage >= total) {
            return ;
          }
          setPage(activePage + 1);
        }}
      >
        <Image src={ props.nextImgUrl ?? ListenBriefImg.RightArrow } alt='go next page' className='w-4 h-4' />
      </div>
    </div>
  );
}


'use client';
import Pagination from '@/components/Pagination/Pagination';
import SmPagination from '@/components/Pagination/SmPagination';
import { usePathname, useRouter } from 'next/navigation';

interface IProps {
  page: number;
  total: number
}

export default function FooterPagination(props: IProps) {

  const pathname = usePathname();
  const router = useRouter();
  const prefix = pathname.split('/').slice(0, -1).join('/');


  return (
    <section className='pagination-part'>
      <Pagination curPage={props.page} 
        onChange={ (newPage) => router.push(`${prefix}/${newPage}`) } 
        total={props.total} 
        className="pt-[60px] pb-[150px] max-md:hidden"></Pagination>
      <SmPagination curPage={props.page} 
        onChange={ (newPage) => router.push(`${prefix}/${newPage}`) } 
      total={props.total} 
        className='font-medium text-sm px-[15px] pt-[25px] pb-[70px] hidden max-md:flex' />
    </section>
  );
}

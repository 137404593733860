'use client';
import { ListenBriefImg } from '@/config/img/listenbiref';
import { dealLng } from '@/utils';
import { Image } from '@nextui-org/react';
import Link from 'next/link';
import { useParams } from 'next/navigation';

interface IProps {
  imgURL?: string;
  goPath?: string;
  title: string;
  author: string;
  onPlay?: () => void;
}

export default function HorizontalCard(props: IProps) {

  const params = useParams();


  return (
    <div className='w-full h-[150px] bg-[rgba(255,255,255,0.08)] rounded-[15px]  border-[1px] border-solid border-[rgba(255,255,255,0.14)] mb-5 flex overflow-hidden items-center'>
      <div className='left-item h-full bg-[#262626]'>
        <div className='p-[15px] w-[110px] relative'>
          <Image
            src={props.imgURL ?? '/test.png'}
            alt={props.title}
            className='bg-contain w-[80px] h-[120px]'
          />
          <div className='play right-5 bottom-5 absolute'>
            <Link  href={`${dealLng(params?.lng as string || 'en')}${props.goPath}`}>
              <Image loading='lazy' src={ListenBriefImg.PlayAudio} className='cursor-pointer w-[35px] h-[35px]' alt='play' />
            </Link>
          </div>
        </div>
      </div>
      <div className='right-item flex-1 px-[15px]'>
        <h3 className='font-medium text-base'>{props.title}</h3>
        <p className='text-sm text-[rgba(255,255,255,0.45)] pt-[5px]'>{props.author}</p>
      </div>
    </div>
  );
}

'use client';
import { IPaginationProps, DotTypeEnum } from '@/types/pagination';
import { useCallback, useState } from 'react';


export default function usePagination(props: IPaginationProps) {
  const {
    curPage,
    total,
    onChange,
    range = 10,
  } = props;
  const [activePage, setActivePage] = useState(curPage > total ? total : curPage);
  const onChangeActivePage = useCallback((newPage: number) => {
    setActivePage(newPage);
    if (onChange) {
      onChange(newPage);
    }
  }, [setActivePage, onChange]);
  
  const setPage = useCallback((pageNumber: number) => {
    if (pageNumber <= 0) {
      onChangeActivePage(1);
    } else if (pageNumber > total) {
      onChangeActivePage(total);
    } else {
      onChangeActivePage(pageNumber);
    }
  }, [total, onChangeActivePage]);
  const showLeftDots = activePage - 1 > Math.floor(range / 2);
  const showRightDots = total - activePage > Math.floor(range / 2) ;
  const rang: any[] = [];
  if (showLeftDots && !showRightDots) {
    rang.push(1, DotTypeEnum.PREV_DOT);
    for (let i = 0; i < range - 2; i++) {
      rang.push(total - (range - 2) + i + 1);
    }
  } else if (!showLeftDots && showRightDots) {
    for (let i = 0; i < range - 2; i++) {
      rang.push(i + 1);
    }
    rang.push(DotTypeEnum.NEXT_DOT, total);
  } else if (!showLeftDots && !showRightDots) {
    for (let i = 0; i < total; i++) {
      rang.push(i + 1);
    }
  } else {
    rang.push(1, DotTypeEnum.PREV_DOT);
    for (let i = 0; i < range - 4; i++) {
      rang.push(activePage - Math.ceil((range - 4) / 2) + i + 1);
    }
    rang.push(DotTypeEnum.NEXT_DOT, total);
  }
  return {
    activePage,
    setPage,
    range: rang,
  };
}
